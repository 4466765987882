// 仓库管理列表表头
export const depotColumns = () => {
  return [
    {
      title: '序号',
      width: 70,
      align: 'center',
      scopedSlots: { customRender: '_index' }
    },
    {
      title: '农户名',
      width: 100,
      align: 'center',
      dataIndex: 'name'
    },
    {
      title: '电话',
      width: 150,
      align: 'center',
      dataIndex: 'phone'
    },
    {
      title: '地址',
      align: 'center',
      dataIndex: 'location',
    },
    {
      title: '清运设施',
      align: 'center',
      dataIndex: 'device',
    },
    {
      title: '上次清运时间',
      align: 'center',
      dataIndex: 'time1',
    },
    {
      title: '预测清运时间',
      align: 'center',
      dataIndex: 'time2',
      scopedSlots: { customRender: 'time2' }
    },
    {
      title: '平均清运间隔',
      align: 'center',
      dataIndex: 'time3',
    },
    // {
    //   title: '操作',
    //   width: 200,
    //   align: 'center',
    //   scopedSlots: { customRender: 'action' }
    // }
  ]
}

// 仓库状态
export const statusList = function () {
  return [
    { value: 'START', label: '启用' },
    { value: 'STOP', label: '停用' }
  ]
}