<template>
  <a-skeleton active :loading="ifSub">
    <div class="details">
      <div class="details-title">
        <div class="details-title-text">基础信息</div>
        <i class="details-title-text-i"></i>
      </div>
      <a-row class="details-content-rowBasicInfo">
        <a-col :span="12"><span>农户名：</span>{{ formItem.name || '-' }}</a-col>
        <a-col :span="12"><span>电话：</span> {{ formItem.phone || '-' }}</a-col>
        <a-col :span="12"><span>地址：</span>{{ formItem.location || '-' }}</a-col>
      </a-row>
      <div class="details-title">
        <div class="details-title-text">清运历史</div>
        <i class="details-title-text-i"></i>
      </div>
      <a-table :columns="columns" :pagination="false" :data-source="data" bordered>
      </a-table>
    </div>
  </a-skeleton>
</template>

<script>
  const columns = [
    {
      title: '#',
      dataIndex: 'key',
      align: 'center'
    },
    {
      title: '清运时间',
      dataIndex: 'name',
      align: 'center'
    },
    {
      title: '清运司机',
      dataIndex: 'money',
      align: 'center'
    },
    {
      title: '清运车辆',
      dataIndex: 'address',
      align: 'center'
    }
  ]
  export default {
    name: 'DepotDetail',
    components: {},
    data () {
      return {
        data: [],
        columns,
        ifSub: false,
        formItem: {},
        labelCol: { span: 4 },
        wrapperCol: { span: 19 },
        tableData: []
      }
    },
    methods: {
      loadData (record) {
        this.formItem = record
        this.data = record.history
      }
    }
  }
</script>

<style scoped>
  .text-title {
    font-size: 17px;
    font-weight: 700;
    font-family: 'Lantinghei SC', 'Open Sans', Arial, 'Hiragino Sans GB', 'Microsoft YaHei', '微软雅黑', 'STHeiti',
    'WenQuanYi Micro Hei', SimSun, sans-serif;
  }
</style>
