<template>
  <page-layout class="fs-page">
    <div slot="headerContent">
      <h1 class="title">清运预测</h1>
    </div>
    <a-card :bordered="false">
      <div class="fs-search-box" style="margin-bottom: 20px;">
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-form-item label="农户名">
              <a-input v-model="searchParams.name" placeholder="请输入农户名称" allowClear/>
            </a-form-item>
            <a-form-item label="地址">
              <a-input v-model="searchParams.location" placeholder="请填写位置" allowClear/>
            </a-form-item>
            <a-form-item class="f-r m-r-none">
              <a-button type="primary" @click="search" icon="search">查询</a-button>
              <a-button class="m-l-6" @click="resetForm" icon="reload">重置</a-button>
            </a-form-item>
          </a-form>
        </div>
        <a-row :gutter="12">
          <a-col :sm="24" :md="12" :lg="6" :xl="6">
            <a-card>
              <a-statistic
                title="三格化粪池"
                value="42"
                suffix="个"
                style="text-align: center;"
              >
              </a-statistic>
            </a-card>
          </a-col>
          <a-col :sm="24" :md="12" :lg="6" :xl="6">
            <a-card>
              <a-statistic
                title="单翁"
                value="157"
                suffix="个"
                style="text-align: center;"
              >
              </a-statistic>
            </a-card>
          </a-col>
          <a-col :sm="24" :md="12" :lg="6" :xl="6">
            <a-card>
              <a-statistic
                title="昨日清运"
                value="17"
                style="text-align: center; color: #1890ff;"
              >
                <template slot="suffix">
                  吨<span style="margin-left: 10px; color: red">同比<a-icon type="arrow-down"/>3%</span>
                </template>
              </a-statistic>
            </a-card>
          </a-col>
          <a-col :sm="24" :md="12" :lg="6" :xl="6">
            <a-card>
              <a-statistic
                title="预计今日清运"
                value="19"
                style="text-align: center; color: red;"
              >
                <template slot="suffix">
                  吨<span style="margin-left: 10px; color: #3f8600">预计<a-icon type="arrow-up"/>5%</span>
                </template>
              </a-statistic>
            </a-card>
          </a-col>
        </a-row>
      </div>
      <a-table
        size="middle"
        :columns="columns"
        :dataSource="tableData"
        :loading="tableLoading"
        :pagination="pagination"
        @change="tableChange"
        :customRow="handleView"
        :rowKey="(record) => record.id"
        bordered
      >
        <template slot="_index" slot-scope="text, record, index">
          {{ searchParams.number * searchParams.size + index + 1 }}
        </template>
        <template slot="time2" slot-scope="time2">
          <span style="color: red" v-if="time2 === '2020-08-24'">
            {{time2}}
          </span>
          <span v-else> {{time2}}</span>
        </template>
      </a-table>
    </a-card>
    <!--添加Modal-->
    <a-modal title="新增仓库" v-model="addModal" width="560px">
      <depot-form ref="addRef" @addSuccess="addSuccess" @addErrodetailLocationr="addError"></depot-form>
      <template slot="footer">
        <a-button :loading="addLoading" @click="handleAddOkWithContinue" type="primary" style="margin-right: 5px;"
        >保存新增
        </a-button>
        <a-button :loading="addLoading" @click="handleAddOkWithCopy" type="primary" style="margin-right: 5px;"
        >保存复制
        </a-button>
        <a-button :loading="addLoading" @click="handleAddOk" type="primary" style="margin-right: 5px;"
        >保存关闭
        </a-button
        >
        <a-button @click="closeAddModal">取消</a-button>
      </template>
    </a-modal>
    <!--修改Modal-->
    <a-modal
      title="修改仓库"
      :visible="editModal"
      @ok="handleEditOk"
      @cancel="closeEditModal"
      :confirmLoading="editLoading"
    >
      <depot-form ref="editRef" @editSuccess="editSuccess" @editError="editError"></depot-form>
    </a-modal>
    <a-drawer
      title="清运详情"
      placement="right"
      :closable="true"
      @close="detailClose"
      :width="600"
      :visible="detailDrawer"
      :mask="false"
    >
      <depot-detail ref="viewRef"></depot-detail>
    </a-drawer>
  </page-layout>
</template>

<script>
  import entityCRUDWithCopy from '../../common/mixins/entityCRUDWithCopy'
  import PageLayout from '@/components/page/PageLayout'
  import SERVICE_URLS from '@/api/service.url'
  import { depotColumns, statusList } from './common/common'
  import DepotForm from './Form'
  import DepotDetail from './Detail'
  import moment from 'moment'

  export default {
    name: 'YList',
    mixins: [entityCRUDWithCopy],
    components: { PageLayout, DepotForm, DepotDetail },
    data () {
      return {
        entityBaseUrl: SERVICE_URLS.equipment2.depotApi,
        searchParams: {
          number: '0',
          size: '10',
          direction: '',
          order: '',
          name: '',
          status: undefined,
          departmentId: undefined,
          location: ''
        },
        initColumns: depotColumns(),
        checkType: statusList(),
        orgTree: [],
        data: [
          {
            id: 7, name: '唐婉', phone: '15295078525', location: '月牙湖3队307', device: '三格式化粪池', time1: '2020-08-18', time2: '2020-08-24', time3: '6天',
            history: [
              { key: '1', name: '2020-08-18', money: '潘振', address: '宁AG2918' },
              { key: '2', name: '2020-08-12', money: '王洪兵', address: '宁AG2880' },
              { key: '3', name: '2020-08-6', money: '王金宝', address: '宁AG1132' },
              { key: '4', name: '2020-08-1', money: '潘振', address: '宁AG2918' },
            ]
          },
          {
            id: 8, name: '丁大全', phone: '13995015927', location: '通贵乡通北村5队', device: '单翁化粪池', time1: '2020-08-19', time2: '2020-08-24', time3: '5天',
            history: [
              { key: '1', name: '2020-08-19', money: '于保平', address: '宁AX753L' },
              { key: '2', name: '2020-08-14', money: '潘振', address: '宁AG2918' },
              { key: '3', name: '2020-08-9', money: '王金宝', address: '宁AG1132' }
            ]
          },
          {
            id: 1, name: '熊根强', phone: '15895012325', location: '通贵乡通南村10队', device: '化粪池', time1: '2020-08-17', time2: '2020-08-24', time3: '7天',
            history: [
              { key: '1', name: '2020-08-17', money: '潘振', address: '宁AG2918' },
              { key: '2', name: '2020-08-10', money: '于保平', address: '宁AX753L' },
              { key: '3', name: '2020-08-3', money: '王金宝', address: '宁AG1132' },
              { key: '4', name: '2020-07-26', money: '于保平', address: '宁AX753L' },
              { key: '5', name: '2020-07-19', money: '王金宝', address: '宁AG1132' }
            ]
          },
          {
            id: 10, name: '郑露', phone: '13825016325', location: '月牙湖3队310', device: '单翁化粪池', time1: '2020-08-18', time2: '2020-08-24', time3: '6天',
            history: [
              { key: '1', name: '2020-08-18', money: '刘定', address: '宁E70988' },
              { key: '2', name: '2020-08-12', money: '于保平', address: '宁AX753L' },
              { key: '3', name: '2020-08-6', money: '王金宝', address: '宁AG1132' }
            ]
          },
          {
            id: 5, name: '宋启福', phone: '15295010526', location: '掌政镇碱富桥7队', device: '三格式化粪池', time1: '2020-08-19', time2: '2020-08-24', time3: '5天',
            history: [
              { key: '1', name: '2020-08-19', money: '王洪兵', address: '宁AG2880' },
              { key: '2', name: '2020-08-15', money: '潘振', address: '宁AG2918' },
              { key: '3', name: '2020-08-10', money: '王金宝', address: '宁AG1132' }
            ]
          },

          {
            id: 2, name: '马彦军', phone: '13695011826', location: '掌政镇茂盛村3队', device: '化粪池', time1: '2020-08-18', time2: '2020-08-25', time3: '7天',
            history: [
              { key: '1', name: '2020-08-18', money: '潘振', address: '宁AG2918' },
              { key: '2', name: '2020-08-11', money: '吴振利', address: '宁AV353M' },
              { key: '3', name: '2020-08-4', money: '马建国', address: '宁AG2933' }
            ]
          },
          {
            id: 3, name: '徐方喜', phone: '13995012627', location: '月牙湖村月牙湖2队', device: '单翁化粪池', time1: '2020-08-20', time2: '2020-08-25', time3: '5天',
            history: [
              { key: '1', name: '2020-08-20', money: '刘定', address: '宁AG2918' },
              { key: '2', name: '2020-08-15', money: '高建国', address: '宁AG2918' },
              { key: '3', name: '2020-08-10', money: '王金宝', address: '宁AG1132' },
              { key: '4', name: '2020-08-5', money: '王金宝', address: '宁AG1132' }
            ]
          },
          {
            id: 4, name: '李建国', phone: '17595210929', location: '月牙湖海陶南村10队', device: '三格式化粪池', time1: '2020-08-19', time2: '2020-08-25', time3: '6天',
            history: [
              { key: '1', name: '2020-08-19', money: '刘定', address: '宁E69043' },
              { key: '2', name: '2020-08-13', money: '于保平', address: '宁AX753L' },
              { key: '3', name: '2020-08-08', money: '王金宝', address: '宁AG1132' },
              { key: '4', name: '2020-08-2', money: '王洪兵', address: '宁AG2880' }
            ]
          },
          {
            id: 6, name: '彭莹玉', phone: '18394510875', location: '月牙湖3队306-1', device: '单翁化粪池', time1: '2020-08-20', time2: '2020-08-25', time3: '5天',
            history: [
              { key: '1', name: '2020-08-20', money: '潘振', address: '宁AG2918' },
              { key: '2', name: '2020-08-15', money: '于保平', address: '宁AX753L' },
              { key: '3', name: '2020-08-10', money: '王金宝', address: '宁AG1132' }
            ]
          },

          {
            id: 9, name: '刘杰', phone: '13595013726', location: '掌政镇碱富桥7队', device: '三格式化粪池', time1: '2020-08-19', time2: '2020-08-25', time3: '6天',
            history: [
              { key: '1', name: '2020-08-19', money: '潘振', address: '宁E69043' },
              { key: '2', name: '2020-08-13', money: '于保平', address: '宁AX753L' },
              { key: '3', name: '2020-08-7', money: '王金宝', address: '宁AG1132' },
              { key: '4', name: '2020-08-1', money: '刘定', address: '宁AG2918' },
              { key: '5', name: '2020-07-25', money: '高建国', address: '宁AG2918' }
            ]
          }
        ],
        valueStyle: {},
        pagination: {
          current: 1,
          total: 1,
          pageSize: 10,
          showSizeChanger: true,
          showTotal: (total, range) => `第 ${range[0]}-${range[1]} 条 共${total}记录`
        }
      }
    },
    created () {
      this.getOrgTree()
    },
    methods: {
      moment,
      tableChange () {
        this.search()
      },
      search () {
        this.tableData = this.data
        this.pagination.total = 32
      },
      getOrgTree () {
        this.$http(this, {
          url: SERVICE_URLS.organization.antTree,
          noTips: true,
          success: (data) => {
            this.orgTree = data.body
          }
        })
      },
      detailClose () {
        this.detailDrawer = false
      },
      handleView (record) {
        return {
          on: {
            click: () => {
              this.detailDrawer = true
              this.$nextTick(() => {
                this.$refs.viewRef.loadData(record)
              })
            }
          }
        }
      }
    }
  }
</script>

<style scoped></style>
